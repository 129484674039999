import './Generator.scss';
import React, { useState } from 'react';

const Generator: React.FunctionComponent = () => {
  const [randomArray, setRandomArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [summ, setSumm] = useState(0);
  const [fields, setFields] = useState({
    total: 0,
    count: 0,
    lowerBound: 5,
    upperBound: 350,
  });

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFields({ ...fields, [event.target.name]: event.target.value });
  }

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function getNumbers(
    count: number,
    total: number,
    lowerBound: number,
    upperBound: number
  ): Array<number> {
    let result: Array<number> = [];
    let currentSum = 0;
    let low;
    let high;
    let calc;
    if (
      upperBound * count < total ||
      lowerBound * count > total ||
      upperBound < lowerBound
    ) {
      setErrorMessage('Pas possible');
    } else {
      setErrorMessage('ok');
    }

    for (let index = 0; index < count; index++) {
      calc = total - currentSum - upperBound * (count - 1 - index);
      low = calc < lowerBound ? lowerBound : calc;
      calc = total - currentSum - lowerBound * (count - 1 - index);
      high = calc > upperBound ? upperBound : calc;

      result[index] = randomIntFromInterval(low, high + 1);
      currentSum += result[index];
    }
    result = result.sort(() => Math.random() - 0.5);

    return result;
  }

  function generateRandomNumber(event: React.MouseEvent) {
    event.preventDefault();
    let interArray: Array<number> = getNumbers(
      +fields.count,
      +fields.total,
      +fields.lowerBound,
      +fields.upperBound
    );

    interArray = interArray.sort(() => Math.random() - 0.5);
    let totalSumm = 0;
    interArray.forEach((item) => {
      totalSumm += item;
    });
    if (totalSumm > fields.total) {
      interArray[0] -= 1;
      totalSumm -= 1;
    }
    setSumm(totalSumm);
    setRandomArray(interArray);
  }

  return (
    <div className={'generator'}>
      <div className="wrapper__input">
        <div>Nombre total</div>
        <input
          className={'input'}
          name="total"
          value={fields.total}
          onChange={onChange}
          type="number"
        />
        <div>Nombre d'itérations</div>
        <input
          className={'input'}
          name="count"
          type="number"
          value={fields.count}
          onChange={onChange}
        />
        <div>Minimum</div>
        <input
          className={'input'}
          name="lowerBound"
          value={fields.lowerBound}
          onChange={onChange}
          type="number"
        />
        <div>Maximum</div>
        <input
          className={'input'}
          name="upperBound"
          type="number"
          value={fields.upperBound}
          onChange={onChange}
        />
        <button onClick={generateRandomNumber} className="button__generate">
          Générer
        </button>
      </div>
      <div className={'result'}>
        {randomArray.map((value, index) => (
          <div key={index}>{value}</div>
        ))}
        <div className="summ_number">{summ}</div>
        <div>{errorMessage}</div>
      </div>
    </div>
  );
};
export default Generator;
