import ReactDOM from 'react-dom';
import React from 'react';
import App from './Components/App/App';
import './global/style/style.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
