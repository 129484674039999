import React from 'react';
import './Header.scss';

const Header: React.FunctionComponent = () => (
  <>
    <header className={'header'}>
      <div className={'header__title'}>Générateur nombre</div>
    </header>
  </>
);

export default Header;
