import React from 'react';
import Generator from '../Generator/Generator';
import Header from '../Header/Header';
import './App.scss';
const App: React.FunctionComponent = () => (
  <>
    <Header />
    <div className={'content'}>
      <Generator />
    </div>
  </>
);

export default App;
